<template>
	<div @scroll="handleScroll" ref="scrollzone" class="wrapper d-flex flex-column flex-grow-1 vld-parent">
		<template v-if="showConfirmationMessage">
			<div class="funds-success-message-container mt-5">
				<div class="funds-checkmark-text-container">
					<div class="funds-checkmark-container">
						<svg class="funds-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="funds-checkmark-circle" cx="26" cy="26" r="25" fill="none"/><path class="funds-checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg> 
						<div class="funds-display-on-ie">
							<svg class="funds-ie-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="funds-ie-checkmark-circle" cx="26" cy="26" r="25" fill="none"/><path class="funds-ie-checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg> 
						</div>
					</div>
					<h1 class="funds-success-done-text">Terminé !</h1>
				</div>
				
				<div class="funds-success-message">
					<h2>Votre contrat a été envoyé pour signature.</h2>
					<p>Vous pouvez à présent fermer cette fenêtre.</p>
				</div>
			</div>
		</template>
		<template v-else>
			<loading :active="isLoading" :can-cancel="false" :is-full-page="true" />
	
			<button class="btn btn-primary submit-btn" @click="submitToVitalSign()">
				Envoyer pour signature
			</button>
	
			<div class="d-flex flex-row flex-grow-1 align-items-stretch mt-4">
				<!-- Éditeur -->
				<div class="editor d-flex flex-column flex-grow-1">
					<div class="container">
						<div class="row">
							<div class="col-md-12" id="pdfManager" v-show="showPdfManager">
								<h1 class="mb-3">Placement des zones de signature</h1>
								<div class="row" id="selectorContainer">
									<div class="col-12 col-md-3" :class="{'col-fixed': fixedRecipients}" id="parametriContainer">
										<h3>Signataires</h3>
										<p class="mb-3">Veuillez cliquer sur le nom des signataires ci-dessous pour les ajouter, puis placez-les librement sur le document.</p>
										<template v-for="(param, idx) in pdfParams">
											<div
												:key="`pdf-param-${idx}`"
												style="position: relative; display: block; height: 40px"
											>
												<div
													class="drag-drop dropped-out ready-to-add"
													@click.prevent="addTagToPdf($event)"
													:data-value="param.value"
												>
													<span class="circle"></span>
													<span class="label">
														{{ param.label }}
													</span>
												</div>
											</div>
										</template>
									</div>
									<div class="col-12 col-md-auto" :class="{'offset-md-3': fixedRecipients}">
										<div class="d-flex flex-row justify-content-between" v-if="pdf">
											<a v-if="pdfPage > 1" href="#" @click.prevent="pdfPrevPage">Page précédente</a>
											<a v-else class="disabled" disabled>Page précédente</a>
											
											<p>{{ pdfPage }} / {{ pdf.numPages }}</p>

											<a v-if="pdfPage < pdf.numPages" href="#" @click.prevent="pdfNextPage">Page suivante</a>
											<a v-else class="disabled" disabled>Page suivante</a>
										</div>
										<div
											id="pageContainer"
											class="pdfViewer singlePageView dropzone nopadding"
											style="background-color: transparent"
										>
											<canvas id="the-canvas" style="border: 1px solid black" />
										</div>
										<div class="d-flex flex-row justify-content-between" v-if="pdf">
											<a v-if="pdfPage > 1" href="#" @click.prevent="pdfPrevPage">Page précédente</a>
											<a v-else class="disabled" disabled>Page précédente</a>
											
											<p>{{ pdfPage }} / {{ pdf.numPages }}</p>

											<a v-if="pdfPage < pdf.numPages" href="#" @click.prevent="pdfNextPage">Page suivante</a>
											<a v-else class="disabled" disabled>Page suivante</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import axios from "@axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import pdfjsLib from "pdfjs-dist/webpack";
pdfjsLib.GlobalWorkerOptions.workerSrc =
	"https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js";

import interact from "interactjs";

export default {
	components: {
		Loading,
	},
	data() {
		return {
			showConfirmationMessage: false,
			showPdfManager: false,
			maxPDFx: 595,
			maxPDFy: 842,
			offsetY: 7,
			pdfPage: 1,
			pdf: null,
			pdfParams: [],
			pdfBase64: null,
			isLoading: true,
			fixedRecipients: false,
		};
	},
	computed: {},
	mounted() {
		axios
			.get(`/signature-requests/${this.$route.params.hash}`)
			.then(({ data }) => {
				this.pdfParams = data.recipients.map((recipient) => ({
					value: `|${recipient.firstname} ${recipient.lastname}|`,
					label: `${recipient.firstname} ${recipient.lastname}`,
				}));

				this.pdfBase64 = data.pdfBase64;

				const loadingTask = pdfjsLib.getDocument({
					data: atob(this.pdfBase64),
				});
				loadingTask.promise.then((pdf) => {
					this.pdf = pdf;

					// Render last page by default
					this.pdfPage = this.pdf.numPages;
					this.renderPdfPage(this.pdf.numPages);

					this.isLoading = false;
				});
			})
			.catch((err) => {
				console.error(err);
				this.$router.push({ name: "error-404" });
				// this.isLoading = false;
			});

		/* The dragging code for '.draggable' from the demo above
		 * applies to this demo as well so it doesn't have to be repeated. */
		// enable draggables to be dropped into this
		interact(".dropzone").dropzone({
			// only accept elements matching this CSS selector
			accept: ".drag-drop",
			// Require a 100% element overlap for a drop to be possible
			overlap: 1,
			// listen for drop related events:
			ondropactivate: (e) => {
				// add active dropzone feedback
				e.target.classList.add("drop-active");
			},
			ondragenter: (e) => {
				var draggableElement = e.relatedTarget,
					dropzoneElement = e.target;
				// feedback the possibility of a drop
				dropzoneElement.classList.add("drop-target");
				draggableElement.classList.add("can-drop");
				draggableElement.classList.remove("dropped-out");
				//draggableElement.textContent = 'Dragged in';
			},
			ondragleave: (e) => {
				// remove the drop feedback style
				e.target.classList.remove("drop-target");
				e.relatedTarget.classList.remove("can-drop");
				e.relatedTarget.classList.add("dropped-out");
				//e.relatedTarget.textContent = 'Dragged out';
			},
			ondrop: (e) => {
				//e.relatedTarget.textContent = 'Dropped';
			},
			ondropdeactivate: (e) => {
				// remove active dropzone feedback
				e.target.classList.remove("drop-active");
				e.target.classList.remove("drop-target");
			},
		});

		interact(".drag-drop")
			.draggable({
				manualStart: true,
				inertia: true,
				restrict: {
					// restriction: "#selectorContainer",
					endOnly: true,
					elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
				},
				autoScroll: true,
				onmove: dragMoveListener,
			})
			.on("move", (e) => {
				const interaction = e.interaction;

				// if the pointer was moved while being held down
				// and an interaction hasn't started yet
				if (interaction.pointerIsDown && !interaction.interacting()) {
					const original = e.currentTarget;
					if (original.classList.contains("cloned")) {
						interaction.start({ name: "drag" }, e.interactable, original);
					} else {
						// // create a clone of the currentTarget element
						// const clone = e.currentTarget.cloneNode(true);
						// clone.classList.add("cloned");
						// console.log(clone);
						// // insert the clone to the page
						// // TODO: position the clone appropriately
						// // document.body.appendChild(clone);
						// document.getElementById("pageContainer").prepend(clone);
						// // start a drag interaction targeting the clone
						// interaction.start({ name: "drag" }, e.interactable, clone);
					}
				}
			});

		function dragMoveListener(e) {
			const target = e.target,
				// keep the dragged position in the data-x/data-y attributes
				x = (parseFloat(target.getAttribute("data-x")) || 0) + e.dx,
				y = (parseFloat(target.getAttribute("data-y")) || 0) + e.dy;
			// translate the element
			target.style.webkitTransform = target.style.transform =
				"translate(" + x + "px, " + y + "px)";
			// update the posiion attributes
			target.setAttribute("data-x", x);
			target.setAttribute("data-y", y);
		}

		// this is used later in the resizing demo
		window.dragMoveListener = dragMoveListener;

		// Prevent native "dragstart" browser's handling
		document.addEventListener("dragstart", (e) => {
			// use interact.js' matchesSelector polyfil to check for match with your draggable target
			if (
				interact.matchesSelector(e.target, ".drag-element, .drag-element *")
			) {
				// prevent and stop the event if it's on a draggable target
				e.preventDefault();
				e.stopPropagation();
			}
		});
	},
	methods: {
		handleScroll() {
			scrollY = this.$refs.scrollzone.scrollTop;
			if (scrollY > 160 && !this.fixedRecipients) {
				this.fixedRecipients = true;
			} else if (scrollY < 160) {
				this.fixedRecipients = false;
			}
		},
		renderPdfPage(pageNumber) {
			for (const el of document.querySelectorAll(`#pageContainer .drag-drop`)) {
				el.style.display = "none";
			}

			for (const el of document.querySelectorAll(
				`#pageContainer .drag-drop[data-page="${pageNumber}"]`
			)) {
				el.style.display = "block";
			}

			this.pdf.getPage(pageNumber).then((page) => {
				const scale = 1.0;
				const viewport = page.getViewport(scale);

				// Prepare canvas using PDF page dimensions
				const canvas = document.getElementById("the-canvas");
				const context = canvas.getContext("2d");
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				// Render PDF page into canvas context
				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				//page.render(renderContext);
				page.render(renderContext).then(
					() => {
						this.pageRendered();
					},
					() => {
						console.log("ERROR");
					}
				);
			});
		},
		addTagToPdf(e) {
			const recipientBoxRect = e.currentTarget.getBoundingClientRect();
			const recipientBoxHeight = e.currentTarget.offsetHeight;
			const pageContainer = document.getElementById("pageContainer");
			const pageContainerHeight = pageContainer.offsetHeight;
			const pageContainerRect = pageContainer.getBoundingClientRect();

			// Calculate the top and left positions
			const topRelative = recipientBoxRect.top - pageContainerRect.top;
			const top = Math.min(topRelative, pageContainerHeight-2*recipientBoxHeight);
			
			const clone = e.currentTarget.cloneNode(true);
			clone.classList.add("cloned");
			clone.classList.remove("ready-to-add");
			clone.setAttribute("data-page", this.pdfPage);
			clone.setAttribute("data-x", 20);
			clone.setAttribute("data-y", top);
			clone.style.transform = `translate(20px, ${top}px)`;

			const btn = document.createElement("a");
			btn.href = '#';
			btn.innerHTML = '<span style="color:#e53935;" class="ml-1"><svg data-v-df52703a="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="trash fill" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-trash-fill b-icon bi"><g data-v-df52703a=""><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"></path></g></svg></span>';
			btn.classList.add("remove-tag");
			btn.addEventListener("click", (e) => {
				e.preventDefault();
				e.target.closest('.drag-drop').remove();
			});

			clone.appendChild(btn);

			document.getElementById("pageContainer").prepend(clone);
		},
		pdfPrevPage() {
			if (this.pdfPage <= 1) return;
			this.pdfPage--;
			this.renderPdfPage(this.pdfPage);
		},
		pdfNextPage() {
			if (this.pdfPage >= this.pdf.numPages) return;
			this.pdfPage++;
			this.renderPdfPage(this.pdfPage);
		},
		pageRendered() {
			this.showPdfManager = true;
		},
		getCoordinates() {
			const validi = [];
			const maxHTMLx = document.getElementById("the-canvas").clientWidth;
			const maxHTMLy = document.getElementById("the-canvas").clientHeight;
			
			for (const el of document.querySelectorAll(".drag-drop.can-drop")) {
				const x = parseFloat(el.getAttribute("data-x"));
				const y = parseFloat(el.getAttribute("data-y"));

				const val = {
					text: el.getAttribute("data-value"),
					page: el.getAttribute("data-page"),
					x: (x * this.maxPDFx) / maxHTMLx,
					y: (y * this.maxPDFy) / maxHTMLy,
					label: el.querySelector(".label").innerHTML,
				};
				validi.push(val);
			}

			const refSize = { width: maxHTMLx, height: maxHTMLy };

			if (validi.length == 0) {
				alert("No placeholder dragged into document");
				return null;
			} else {
				console.log(JSON.stringify(validi));
				console.log(JSON.stringify(refSize));
				return {
					refSize,
					tags: validi,
				};
			}
		},
		submitToVitalSign() {
			this.isLoading = true;

			axios
				.post(
					`/signature-requests/${this.$route.params.hash}/send-to-vitalsign`,
					this.getCoordinates()
				)
				.then(({ data }) => {
					console.log(data);
					// window.location.href = data["hydra:member"][0]["link"];
					this.showConfirmationMessage = true;
					window.scrollTo(0, 0);
					document.dispatchEvent(new CustomEvent('redirect'));
				})
				.catch((err) => {
					console.error(err);
					alert("Une erreur est survenue. Veuillez réessayer ultérieurement");
					this.isLoading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">

.col-fixed {
	position: fixed;
    height: 100%;
    max-height: 95vh;
    z-index: 1;
    top: 10px;
    width: 250px;
}
.wrapper {
	overflow: auto;
}

.tableWrapper {
	overflow-x: auto;
}

.resize-cursor {
	cursor: ew-resize;
	cursor: col-resize;
}

#messageContainer {
	display: none;
}

#outer-dropzone {
	height: 140px;

	touch-action: none;
}

#inner-dropzone {
	height: 80px;
}

.dropzone {
	background-color: #ccc;
	border: dashed 4px transparent;
	border-radius: 4px;
	margin: 10px auto 30px;
	padding: 10px;
	width: 100%;
	transition: background-color 0.3s;
}

.drop-active {
	border-color: #aaa;
}

.drop-target {
	background-color: #29e;
	border-color: #fff;
	border-style: solid;
}

.drag-drop {
	display: inline-block;
	position: absolute;
	z-index: 1000000;
	min-width: 40px;
	padding: 0em 0.5em;
	padding-left: 0;
	color: #fff;
	background: #fff;
	border: 1px solid #ccc;
	padding: 4px 6px 4px 2px;

	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px);

	transition: background-color 0.3s;
	line-height: 10px;
}

.drag-drop.ready-to-add {
	cursor: pointer !important;
	border: 1px solid #2444EC;
	border-radius: 8px;
}

.drag-drop.can-drop {
	color: #000;
	// background-color: transparent;
	opacity: 0.9;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=90);
	-moz-opacity: 0.9;
	-khtml-opacity: 0.9;
}

.nopadding {
	padding: 0 !important;
	margin: 0 !important;
}

.circle {
	width: 10px;
	height: 10px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	background: #2444EC;
	float: left;
	display: inline-block;
	margin-top: 1px;
	margin-right: 2px;
}

.dropped-out {
	display: block;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
	width: 200px;
	color: black;
}

.page-item {
	cursor: pointer;
}

.pager {
	margin-bottom: 30px !important;
	margin-top: 0px !important;
	margin-bottom: -31px !important;
}

.drag-drop.dropped-out .value {
	font-size: 12px !important;
}

#the-canvas {
	height: 842px;
	width: 595px;
}

.submit-btn {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 100;
}

.funds-success-message-container {
  font-family: Roboto, "Segoe UI", "Helvetica Neue", sans-serif !important;
  color: #333;
  margin-top: 100px;
  max-width: 500px;
  margin: 0 auto;
}

.funds-checkmark-text-container {
  display: block;
  width: auto !important;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.funds-checkmark-container {
  height: 40px;
  padding: 0 4px 0 0px;
  display: inline-block;
  width: 70px;
  margin: 0 auto;
}

.funds-checkmark-container:after {
  clear: both;
}

.funds-success-done-text {
  vertical-align: middle;
  margin: 0;
  padding: 15px 0 0 0;
  display: inline;
}

.funds-success-message {
  text-align: center;
  margin-top: 2em;
}

.funds-success-message h2 {
  margin-top: 0px;
}

h1,
h2 {
  font-weight: 700;
  margin-top: 0px;
}

h1 {
  font-size: 28pt;
  margin-top: 45px;
}

h2 {
  font-size: 18pt;
  margin-top: 25px;
}

p {
  font-size: 12pt;
  padding-bottom: 1em;
  line-height: 27.2px;
}

a.disabled {
	cursor: not-allowed;
	color: #ccc;
}

/* CHECKMARK */
.funds-checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: #2444EC;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.funds-checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  -webkit-box-shadow: inset 0px 0px 0px #2444EC;
  box-shadow: inset 0px 0px 0px #2444EC;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.funds-checkmark-check {
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.funds-display-on-ie {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .funds-display-on-ie {
    display: block;
  }

  .funds-checkmark {
    display: none;
  }

  .funds-ie-checkmark-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 5;
    stroke-miterlimit: 10;
    stroke: #2444EC;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .funds-ie-checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: #ffffff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    -webkit-box-shadow: inset 0px 0px 0px #2444EC;
    box-shadow: inset 0px 0px 0px #2444EC;
    -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .funds-ie-checkmark-check {
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@-webkit-keyframes fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 30px #2444EC;
    box-shadow: inset 0px 0px 0px 30px #2444EC;
  }
}

@keyframes fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 30px #2444EC;
    box-shadow: inset 0px 0px 0px 30px #2444EC;
  }
}

@media only screen and (max-width: 768px) {
  .funds-checkmark-text-container {
    display: block;
  }
  .funds-checkmark-container {
    height: auto;
    padding: 0;
    display: block;
    width: 100%;
  }
}

</style>
